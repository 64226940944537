import { useMemo, useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import Grid from "@mui/material/Grid";

import SoftTypography from "components/SoftTypography";
import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import { NumericFormat } from 'react-number-format'; 
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import callFetch from "helpers/callFetch";
import SoftSelect from "components/SoftSelect";
import LoadingCard from "components/LoadingCard";

function QuotationLineChart() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [year, setYear] = useState('');
    const [allYears, setAllYears] = useState([
        { value: '2023', label: '2023' },
        { value: '2022', label: '2022' }, 
        { value: '2021', label: '2021' }, 
        { value: '2020', label: '2020' }, 
        { value: '2019', label: '2019' }, 
        { value: '2019', label: '2019' },
    ]);
    
    useEffect(() => {
        setLoading(true);
        callFetch("dashboard/line-chart/quotation"+(year ? '/'+year : ''), "GET", []).then((res) => {
            setData(res);
            setLoading(false);
        });
    }, [year]); 

    return (
        <>
            {Cookies.get('permissions').indexOf("All Sales Numbers") !== -1 || Cookies.get('permissions').indexOf("Own Sales Numbers") !== -1 ? (
                <>
                <Grid item xs={12} sm={4} className="dashboard-linechart">
                    {data?.data ? (
                        <>
                        <MiniGradientLineChart
                            title={t("Angebot")}
                            description={
                            <>                      
                                <SoftTypography variant="h5" fontWeight="bold" style={{float: "right", fontSize: "1rem"}}>
                                    <NumericFormat 
                                        value={data?.total} 
                                        displayType="text" 
                                        thousandSeparator={"."} 
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix={' € '}
                                    />
                                    {/*<SoftTypography variant="button" color="success" fontWeight="bold">+55%</SoftTypography>*/}
                                </SoftTypography>
                                <div
                                    className="chart-dropdown-option"
                                    style={{
                                        width: '80px',
                                        float: 'right',
                                        marginRight: '5px'
                                    }}
                                >
                                    <SoftSelect
                                        placeholder={t('Select')}
                                        options={allYears}
                                        value={year ? {label: year, value: year} : allYears[0]}
                                        size={'small'}
                                        onChange={(e)=>{
                                            setYear(parseInt(e.value));
                                        }} 
                                    />
                                </div>
                            </>
                            }
                            chart={data?.data}
                        />
                        </>
                    ) : <PlaceholderCard icon={""} title={{ variant: "p", text: "Loading..." }} hasBorder height="162px" />}
                </Grid>
            </>
        ) : <></>}
        </>
    )
}

export default QuotationLineChart;