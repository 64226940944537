import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import ProjectIndexTable from './IndexTable';

function ProjectIndex() {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Resell Projekte')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <ProjectIndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectIndex;
