// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftAlert from "components/SoftAlert";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

function Connect(props) {
  let params = useParams();
  const { t } = useTranslation();  
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false); 

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
} = useForm();

  useEffect(() => {
    setValue('id', props.id)
  });

  useEffect(() => {
    callFetch("get-connect-info", "GET", []).then((res) => {
      setData(res.data);
    });
}, [refresh]);

  const SendRequest = (id) => {
    console.log('send request');
  }

  console.log(data);

  return (
    <Card id="connect-social" className={props?.className}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('Connect')}</SoftTypography> 
        {/*submitSuccess ? <SoftAlert color="success" mt={3} dismissible>{t('Password Changed Successfully')}</SoftAlert> : ' '*/} 
      </SoftBox>
      <SoftBox  pb={3} px={3}>

        {props?.type == 'sendRequest' ? (
          <>
            <SoftButton
              onClick={(e) => {
                SendRequest(props.id);
              }}
              variant="text"
              color="dark"
            className="px-0"
            ><i className="fa-brands fa-google"></i>&nbsp; Send Request for Connect Google Calendar</SoftButton>
          </>
        ) : (
          <>
          {data?.google_calendar_connect ? (
            <SoftButton
              href={data?.google_calendar_connect}
              //target="_blank"
              variant="text"
              color="dark"
              className="px-0"
            ><i className="fa-brands fa-google"></i>&nbsp; Connect Google Calendar</SoftButton>
          ) : ''}
          </>
        )}
      </SoftBox>
      
    </Card>
  );
}

export default Connect;
