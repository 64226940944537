import { useMemo, useEffect, useState, useRef } from "react";
// @mui material components
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import LoadingCard from "components/LoadingCard";

function TermineQouteTable(props) {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  //const [users, setUsers] = useState([]);
  const [filterData, setFilterData] = useState({
      verkaufer: 0,
      created_at: 'All',
  });
  
  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
  
    // Using the browser's default locale.
    return date.toLocaleString([], { month: 'long' });
  }

  useEffect(() => {
    if(!props?.filterData?.created_at) return;
    
    if(props?.filterData?.created_at != filterData?.created_at){
        var data = filterData;
        data.created_at =  props?.filterData?.created_at;
        setFilterData(data);
        setRefresh(refresh+1)
    }
  }, [props?.filterData?.created_at]);


  useEffect(() => {
    setLoading(true);
    callFetch("statistics/termine-qoute-table", "POST", filterData, setError).then((res) => {
        setData(res.data);
        setLoading(false);
        if (!res.ok) return;
    });
  }, [refresh]);


  function PagesBodyCell({ rows, noBorder, color, sx, width, align}) {
    const { light } = colors;
    const { borderWidth } = borders;

    const renderRows = rows.map((row, index) => {
      return (
        <SoftBox
          key={row}
          component="td"
          xs={{ alignItems: "center", textAlign: "center" }}
          /*
          borderBottom={
            noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
          }
          */
          width={width && width[index] ? width[index] : "max-content"}
          textAlign={align && align[index] ? align[index] : "center"}
          pt={1}
          pb={1}
          pl={5}
          pr={5}
          // alignItems="center"
        >
          <SoftTypography
            display="block"
            variant="button"
            fontWeight="medium"
            color={color ? color : "dark"}
          >
            {row}
          </SoftTypography>
        </SoftBox>
      );
    });

    return <TableRow sx={sx} style={{width: '100%', display: 'flex'}}>{renderRows}</TableRow>;
  }

  // Setting default values for the props for PagesBodyCell
  PagesBodyCell.defaultProps = {
    noBorder: false,
  };

  // Typechecking props for the PagesBodyCell
  PagesBodyCell.propTypes = {
    rows: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    noBorder: PropTypes.bool,
  };

  return (
    <>
      {loading ? (
        <LoadingCard
          sx={{height: '440px'}}
        />
      ) : (
        <SoftBox>
          <TableContainer
            sx={{
              boxShadow: "none",
              backgroundColor: "#CBD5E0",
            }}  
          >
            <Table>
              <TableBody>
                <PagesBodyCell
                  sx={{
                    backgroundColor: "#0048B1",
                    textAlign: "center",
                    alignItems: "center",
                    height: '57px',
                  }}
                  align={['left']}
                  width={['40%', '20%', '20%', '20%']}
                  color={'light'}
                  rows={["Monat", 'Termine 1-7', 'Termine 1-4', "Termine Q."]}
                />
              </TableBody>

              <TableBody 
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  height: '326px',
                  overflowY: 'auto',
                  display: 'block',
                  width: '100%'
                }}
              >

                {data?.projects ? data?.projects.map((tr, index) =>(
                  <>
                    <PagesBodyCell
                      key={index}
                      
                      sx={{ textAlign: "center", alignItems: "center", backgroundColor: (index % 2 == 0 ? "#D9D9D9" : "#ffffff"), height: '41px' }}
                      align={['left']}
                      width={['40%', '20%', '20%', '20%']}
                      rows={[
                        getMonthName(tr?.month),
                        (tr?.total_termine_1_7 > 0 ? tr?.total_termine_1_7 : '--'),
                        (tr?.total_termine_1_4 > 0 ? tr?.total_termine_1_4 : '--'),
                        (Number.isInteger(((tr?.total_termine_1_4/tr?.total_termine_1_7)*100)) ?
                        
                        ((tr?.total_termine_1_4/tr?.total_termine_1_7)*100)+'%' :
                        <>
                          {((tr?.total_termine_1_4/tr?.total_termine_1_7)*100) ? (
                            <NumericFormat 
                              value={((tr?.total_termine_1_4/tr?.total_termine_1_7)*100)} 
                              displayType="text" 
                              thousandSeparator={"."} 
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale
                              suffix={'%'}
                            />
                          ) : '--'}
                        </>
                        )
                      ]}
                    />              
                  </>
                )) : ''}
                
                
                {/*
                  <PagesBodyCell sx={{ backgroundColor: "#ffffff", height: '41px' }} align={['left']} rows={["Februar", 233, 123, "34%"]} />
                  <PagesBodyCell sx={{ backgroundColor: "#D9D9D9", height: '41px' }} align={['left']} rows={["Marz", 233, 123, "34%"]} />
                  <PagesBodyCell sx={{ backgroundColor: "#ffffff", height: '41px' }} align={['left']} rows={["April", 233, 123, "34%"]} />
                  <PagesBodyCell sx={{ backgroundColor: "#D9D9D9", height: '41px' }} align={['left']} rows={["Mai", 233, 123, "34%"]} />
                  <PagesBodyCell sx={{ backgroundColor: "#ffffff", height: '41px' }} align={['left']} rows={["Juni", 233, 123, "34%"]} />
                  <PagesBodyCell sx={{ backgroundColor: "#D9D9D9", height: '41px' }} align={['left']} rows={["Juli", 233, 123, "34%"]} />
                  <PagesBodyCell sx={{ backgroundColor: "#ffffff", height: '41px' }} align={['left']} rows={["August", 233, 123, "34%"]} />
                */}            
              </TableBody>
              <TableBody 
                style={{
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <PagesBodyCell
                  sx={{ textAlign: "center", alignItems: "center", backgroundColor: "#CBD5E0", height: '57px' }}
                  width={['40%', '20%', '20%', '20%']}
                  align={['left']}
                  rows={[
                    "In Total",
                    (data?.total?.total_termine_1_7 > 0 ? data?.total?.total_termine_1_7 : '--'),
                    (data?.total?.total_termine_1_4 > 0 ? data?.total?.total_termine_1_4 : '--'),

                    (data?.total?.percentage ? (
                      <NumericFormat 
                        value={data?.total?.percentage} 
                        displayType="text" 
                        thousandSeparator={"."} 
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={'%'}
                      />
                    ) : '--')
                  ]} />
              </TableBody>
            </Table>
          </TableContainer>
        </SoftBox>
      )}
    </>
  );
}

export default TermineQouteTable;
